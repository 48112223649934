<template>
  <v-footer class="d-flex justify-center align-center">
    <v-container class="footer">
      <v-row no-gutters class="align-center">
        <v-col cols="12" md="6" class="d-flex justify-start align-center">
          <!-- Logo Iperal -->
          <a to="/" aria-label="iperal-supermercati">
            <v-img
              alt="iperal-supermercati"
              src="/footer/Iperal.svg"
              class="my-3"
              contain
              height="50"
              width="110"
              max-width="110"
            />
          </a>
          <!-- Logo Facebook -->
          <a
            aria-label="Trovaci su Facebook"
            href="https://it-it.facebook.com/Iperal/"
            @click.prevent="
              goToExternalPage('https://it-it.facebook.com/Iperal/')
            "
          >
            <v-img
              src="/footer/facebook.svg"
              alt="facebook"
              class="my-3 ml-6"
              contain
              height="27"
              width="27"
              max-width="27"
            />
          </a>
          <!-- Logo Instagram -->
          <a
            aria-label="Trovaci su Instagram"
            href="https://www.instagram.com/iperal_official/"
            @click.prevent="
              goToExternalPage('https://www.instagram.com/iperal_official/')
            "
          >
            <v-img
              alt="instagram"
              src="/footer/instagram.svg"
              class="my-3 ml-3"
              contain
              height="27"
              width="27"
              max-width="27"
            />
          </a>
          <!-- Logo Youtube -->
          <a
            aria-label="Trovaci su Youtube"
            href="https://www.youtube.com/c/IperalSpaOfficial"
            @click.prevent="
              goToExternalPage('https://www.youtube.com/c/IperalSpaOfficial')
            "
          >
            <v-img
              alt="youtube"
              src="/footer/youtube.svg"
              class="my-3 ml-3"
              contain
              height="27"
              width="31"
              max-width="31"
            />
          </a>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column align-start align-md-end contacts"
        >
          <!-- <span class="white--text call-number">Chiamaci al Numero Verde </span>

          <a href="tel:800-232730" class="d-flex align-center tel-link">
            <v-img src="/footer/Phone.svg" class="my-3" contain height="20" />
            <strong class="ml-2  white--text">800-232730</strong>
          </a>
          <p class="white--text text-left text-md-right">
            Gli orari del nostro servizio clienti sono: <br />
            Dal lunedì al sabato: 9:00 - 18:00 <br />
            <a class="white--text" href="mailto:servizioclienti@iperal.it">
              servizioclienti@iperal.it
            </a>
          </p> -->
          <span class="white--text customer-service">SERVIZIO CLIENTI</span>
          <p class="white--text text-left text-md-right customer-sub">
            Hai bisogno di aiuto?<br />
          </p>
          <v-btn
            color="white"
            text
            rounded
            class="customer-link"
            link
            to="/servizio-clienti"
          >
            Contattaci
          </v-btn>
        </v-col>
      </v-row>

      <v-row no-gutters class="align-center mt-3">
        <v-col cols="12" md="9">
          <div class="white--text company-info">
            © IPERAL SUPERMERCATI S.P.A. con socio unico C.F./P.IVA 11023300962
            <br />
            Sede Legale: Via La Rosa, 354 - 23010 Piantedo (SO) - Sede
            Amministrativa: Via La Rosa, 354 23010 Piantedo (SO) - Tel.
            0342/606811 <br />
          </div>
          <div
            class="footer-links d-flex flex-column flex-md-row align-start align-md-center"
          >
            <div class="link-box" v-for="link in links" :key="link.ID">
              <v-btn
                v-if="link.to"
                color="white"
                text
                rounded
                class="footer-link"
                link
                :to="link.to"
              >
                {{ link.title }}
              </v-btn>
              <v-btn
                v-else-if="link.url"
                color="white"
                text
                rounded
                class="footer-link"
                link
                @click="goToExternalPage(link.url)"
              >
                {{ link.title }}
              </v-btn>
            </div>
            <div class="link-box">
              <a
                link
                class="footer-link v-btn v-btn--rounded v-btn--router v-btn--text theme--light v-size--default white--text"
                @click="openCookies()"
              >
                {{ $t("footer.links.cookies") }}
              </a>
            </div>
            <div class="link-box">
              <a
                link
                target="_system"
                href="https://adv.iperal.it/accessibilitaecommerce"
                class="footer-link v-btn v-btn--rounded v-btn--router v-btn--text theme--light v-size--default white--text"
                @click="clicked"
              >
                {{ $t("footer.links.accessibility") }}
              </a>
            </div>

            <!-- <div class="link-box">
            <v-btn
              color="white"
              text
              rounded
              class="footer-link"
              @click="reopenCookies()"
            >
              Gestione cookie
            </v-btn>
          </div> -->
          </div>
        </v-col>

        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column align-start align-md-end download-app"
          v-if="!$platform_is_cordova"
        >
          <span class="white--text mb-1">App Iperal Spesa online</span>

          <div class="d-flex align-center">
            <a
              @click="
                goToExternalPage(
                  'https://play.google.com/store/apps/details?id=it.digitelematica.ebsn.iperal&hl=it'
                )
              "
            >
              <v-img src="/footer/google-play.svg" contain height="35" />
            </a>

            <a
              @click="
                goToExternalPage(
                  'https://itunes.apple.com/it/app/iperal.it-spesa-online/id1003843088?mt=8'
                )
              "
            >
              <v-img
                src="/footer/app-store.svg"
                class="ml-2"
                contain
                height="35"
              />
            </a>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pb-1">
          <span class="white--text">
            Realizzato da Sviluppocreativo.it e Digitelematica.it -
            {{ version }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<style scoped lang="scss">
.footer {
  min-height: 114px;
  font-size: 12px;
  margin: 0;
  padding-bottom: 0;
  .tel-link {
    text-decoration: none;
  }
  .contacts {
    .call-number {
      font-size: 13px;
    }
    strong {
      font-size: 23px;
      font-weight: 800;
    }
  }
  .company-info {
    font-size: 11px;
  }
  .download-app {
    span {
      font-size: 13px;
    }
  }
  .customer-service {
    font-size: 18px;
    font-weight: bold;
  }
  .customer-sub {
    font-size: 14px;
  }
  .customer-link {
    background-color: $primary;
    text-transform: none;
  }
  .footer-links {
    margin: 10px 0px;
    width: 100%;
    flex-flow: wrap;
    .link-box {
      display: flex;
      &:after {
        color: #fff;
        content: "|";
        display: inline-block;
        padding: 0 5px;
      }
      &:last-child:after {
        content: "";
      }
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        &:after {
          content: "";
        }
      }
    }
    .footer-link {
      font-size: 11px;
      padding: 0 5px;
      height: 18px;
      justify-content: flex-start;
      min-width: auto;
    }
    .cookie-managment {
      // padding: 0px 5px;
      //  font-size: 11px;
      // letter-spacing: 0.0892857143em;
      //  cursor: pointer;
      &:hover {
        //   background-color: #555;
        //opacity: 0.8;
      }
    }

    .v-btn__content {
      justify-content: flex-start;
    }
  }
}
</style>
<script>
import CmService from "~/service/cmService";
import { mapActions } from "vuex";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "Footer",
  components: {},
  mixins: [clickHandler],
  data() {
    return {
      links: [
        {
          ID: 1,
          title: "Regolamento",
          slug: "regolamento",
          to: { name: "Page", params: { pageName: "regolamento" } }
        },
        {
          ID: 2,
          title: "Libro ingredienti",
          slug: "i-nostri-prodotti",
          to: "/faq/i-nostri-prodotti"
        },

        {
          ID: 3,
          title: "Richiamo prodotti",
          url: "https://www.iperal.it/Richiamo-prodotti/"
        },
        {
          ID: 4,
          title: "Agevolazioni di consegna",
          slug: "consegna agevolata",
          to: { name: "ConsegnaAgevolata" }
        },
        {
          ID: 5,
          title: "Domande Frequenti",
          slug: "le-domande-piu-frequenti",
          to: { name: "FaqCategory" }
        },
        {
          ID: 6,
          title: "Privacy Policy",
          slug: "privacy-policy",
          to: { name: "Page", params: { pageName: "privacy-policy" } }
        },
        {
          ID: 7,
          title: "Cookie Policy",
          slug: "cookie-policy",
          to: { name: "Page", params: { pageName: "cookie-policy" } }
        },
        {
          ID: 8,
          title: "Servizio Clienti",
          slug: "servizio-clienti",
          to: { name: "Servizio Clienti" }
        }
      ]
    };
  },
  computed: {
    version() {
      return global.config.version;
    }
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedReopenCookies: "app/updatedReopenCookies"
    }),
    reopenCookies() {
      this.updatedReopenCookies(true);
    },
    fetchMenu() {
      CmService.getMenuByLocation("footer").then(data => (this.links = data));
    },
    gotoStaticPage(linkHref) {
      this.$router.push({ name: "Page", params: { pageName: linkHref } });
      this.updatedDrawerLeft(false);
    },
    goToExternalPage: function(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        //if app open on _system browser
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    },
    openCookies() {
      if (window.Cookiebot) {
        window.Cookiebot.show();
      }
    }
  }
};
</script>
